import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { Col, Container, Row } from 'react-bootstrap';
import { ReactComponent as Logo } from '../../assets/cg-icon-globe.svg';
import cloneDeep from 'lodash.clonedeep';
import {
  FeedbackEntity,
  FeedbackGameEvent
} from '../../types/feedback-document';
import { FeedbackDocumentContext } from '../../contexts/feedback-document';
import { UpdateFeedbackDocumentState } from '../../utils/feedback-document';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { DisplayLanguageFeedbackContext } from '../../contexts/display-languages-feedback';
import { getFeedbackAsync } from '../../services/feedbacks';

export const FeedbackGames = () => {
  const [state, setState] = useContext(FeedbackDocumentContext);
  const [feedbackDisplayLangContext] = useContext(
    DisplayLanguageFeedbackContext
  );

  const { feedbackCode } = useParams();
  const navigate = useNavigate();
  const [games, setGames] = useState<FeedbackGameEvent[]>(
    state.eventGames ?? []
  );
  const [selectedGames, setSelectedGames] = useState<FeedbackGameEvent[]>([]);
  const [status, setStatus] = useState<string>('Completed');

  const handleSelectGame = (gameCode: string, value: boolean) => {
    let newSelectedGames = cloneDeep(selectedGames);
    let newFeedbackDocument = cloneDeep(state.feedbackDocument);

    if (newFeedbackDocument?.games === undefined) {
      newFeedbackDocument!.games = [];
    }

    const isExistingSelected = selectedGames.find(
      (i: FeedbackGameEvent) => i.code === gameCode
    );

    if (value) {
      if (!isExistingSelected) {
        newSelectedGames.push(
          games.find((i: FeedbackGameEvent) => i.code! === gameCode!)!
        );
      }
    } else {
      const indexItem = selectedGames.findIndex(
        (i: FeedbackGameEvent) => i.code === gameCode
      );
      newSelectedGames.splice(indexItem, 1);
    }

    setSelectedGames(newSelectedGames);
    newFeedbackDocument!.games = newSelectedGames;
    //update feedback document
    setState(UpdateFeedbackDocumentState(state!, newFeedbackDocument!));
  };

  const handleNext = async () => {
    navigate(`/feedbacks/${feedbackCode}/form`);
  };

  const validateFeedbackAsync = async () => {
    if (feedbackCode) {
      let response = await getFeedbackAsync(feedbackCode!);
      if (response) {
        setStatus(response.status);
      }
    }
  };

  useEffect(() => {
    validateFeedbackAsync();
  }, []);

  return (
    <>
      {status === 'Running' && (
        <div className={'layout layout--pre-game'}>
          <div className={'layout__container'}>
            <Container className={'rounded shadow'}>
              <Row className={'align-items-center'}>
                <Col />
                <Col className={'text-center'}>
                  {state.feedbackDocument?.logoUrl ? (
                    <img
                      src={state.feedbackDocument?.logoUrl}
                      alt="img"
                      style={{ width: 64 }}
                    />
                  ) : (
                    <Logo style={{ width: 64 }} />
                  )}
                </Col>
                <Col />
              </Row>
              <Row className={'gy-4 mt-4'}>
                <Col xs={12} className={'text-center'}>
                  <h1>
                    {generateTitleById(
                      '77284806-5846-4da9-a92f-1aee27ad6943',
                      state,
                      feedbackDisplayLangContext.displayLanguageSelected
                        .resources!,
                      'feedback'
                    ) || 'Please select the activity to provide feedback on.'}
                  </h1>
                </Col>
                <Col>
                  {games &&
                    games?.map((game: FeedbackGameEvent, index: number) => {
                      const isSelected = selectedGames.find(
                        (i: FeedbackGameEvent) => i.code === game.code
                      );
                      return (
                        <div key={index}>
                          <Checkbox
                            id={`game-feedback-${game.code}`}
                            className={'mr-2'}
                            label={game.name}
                            name={'game'}
                            checked={isSelected !== undefined}
                            onChange={(e) =>
                              handleSelectGame(
                                game.code,
                                e.target.value as boolean
                              )
                            }
                          />
                        </div>
                      );
                    })}
                </Col>
                <Col xs={12}>
                  <Button
                    className={'k-button--gradient w-100'}
                    themeColor={'primary'}
                    onClick={handleNext}>
                    {generateTitleById(
                      '6101dd6b-0e30-4518-b9d9-37a4173c1031',
                      state,
                      feedbackDisplayLangContext.displayLanguageSelected
                        .resources!,
                      'feedback'
                    ) || 'Next'}
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}

      {status !== 'Running' && (
        <div className={'mask'}>
          <Container>
            <Row className={'mt-4'}>
              <Col className={'text-center'}>
                {state.feedbackDocument?.logoUrl ? (
                  <img
                    src={state.feedbackDocument?.logoUrl}
                    alt={''}
                    style={{ maxWidth: '10rem' }}
                    className={'rounded'}
                  />
                ) : (
                  <Logo style={{ width: 64 }} />
                )}
              </Col>
            </Row>
            <Row className={'mt-4'}>
              <Col className={'text-center'}>
                {status === 'NotStarted' && (
                  <span>
                    Thank you for your interest in contributing your valuable
                    feedback! Please note that the survey period has not started
                    yet.
                  </span>
                )}
                {status === 'Completed' && (
                  <span>
                    Thank you for your interest in contributing your valuable
                    feedback! Please note that the survey period has now ended.
                  </span>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};
