import React, { useContext, useState } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { useSessionStorage } from 'usehooks-ts';
import { GameDocumentContext } from '../../contexts/game-document';
import { PlayerContext } from '../../contexts/player';
import { validatePlayer } from '../../services/players';
import { PreGameWizard } from '../../types/pre-game';
import { UpdatePlayerContext } from '../../utils/player-state';
import { WizardContent } from '.';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../contexts/display-languages';

const StepThree = ({ nextStepCallback, currentStep = 1 }: PreGameWizard) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [playerState, setPlayerState] = useContext(PlayerContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  const [selectedPlayerName, setSelectedPlayerName] = useSessionStorage(
    'last-player-name',
    playerState.playerState?.name ?? ''
  );

  const [errorValidation, setErrorValidation] = useState<string>('');
  const [isError, setError] = useState<boolean>(false);

  const handleClickNext = async () => {
    if (
      selectedPlayerName === '' ||
      selectedPlayerName.length < 2 ||
      selectedPlayerName.length > 128
    ) {
      setErrorValidation('Please enter a name that is 2 - 128 characters');
      setError(true);
      return;
    }

    const response = await validatePlayer(
      gameDocument.gameCode!,
      selectedPlayerName
    );

    if (response == null) {
      setErrorValidation('The game has ended');
      setError(true);
    } else if (response) {
      setPlayerState((prev) =>
        UpdatePlayerContext(prev, {
          ...prev.playerState!,
          name: selectedPlayerName
        })
      );
      nextStepCallback(currentStep + 1);
    } else {
      setErrorValidation('Player name already taken');
      setError(true);
      return;
    }
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPlayerName(e.target.value);
  };

  return (
    <WizardContent
      wizardTitle={
        generateTitleById(
          '3cf798f7-da4d-47b4-a3d2-713191b0d3cc',
          gameDocument,
          displayLanguageContext.displayLanguageSelected.resources!,
          'game'
        ) ||
        "Now, give yourself a fun player name. It doesn't have to be your real name"
      }
      nextButtonText={
        generateTitleById(
          '079c039a-8207-4404-8eb6-558cf5d8477b',
          gameDocument,
          displayLanguageContext.displayLanguageSelected.resources!,
          'game'
        ) || 'Next'
      }
      handleNextButtonClicked={handleClickNext}>
      <Form.Group
        as={Col}
        md={'12'}
        controlId={'validationCustomUsername'}
        className={`mb-4 ${isError && 'was-validated'}`}>
        <Form.Label className={isError ? 'text-danger' : 'text-dark'}>
          {generateTitleById(
            'ff5ffdc0-5b2d-4b40-a1b0-66f3c886df89',
            gameDocument,
            displayLanguageContext.displayLanguageSelected.resources!,
            'game'
          ) || 'Player name'}
        </Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type={'text'}
            placeholder={''}
            aria-describedby={'inputGroupPrepend'}
            required
            className={'py-2'}
            size={'lg'}
            onChange={handleChangeName}
            value={selectedPlayerName}
          />
          <Form.Control.Feedback
            type={'invalid'}
            style={{ display: isError ? 'block' : 'none' }}>
            {errorValidation}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </WizardContent>
  );
};

export default StepThree;
